/* styles.css */
@keyframes slide-up {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.animate-slide-up {
  animation: slide-up 0.5s ease-out;
}

.login-overlay {
  background-color: #004d40;
  color: #ffffff; /* White text */
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 33vh; /* Cover half the screen */
  padding: 20px;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.login-overlay-mobile {
  background-color: #004d40;
  color: #ffffff; /* White text */
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 15vh; /* Cover half the screen */
  padding: 20px;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.login-overlay-content {
  max-width: 200%; /* Adjusted width to bring content closer to the left */
  text-align: left; /* Align text to the left */
  padding-left: 0px; /* Reduced padding to move text left */
}

/* Wrapper for content to blur */
.content-wrapper {
  filter: none; /* Default state */
  transition: filter 0.3s ease-in-out;
}

.content-wrapper.blurred {
  filter: blur(8px); /* Blur content */
  pointer-events: none; /* Disable interaction */
}

.blurred-item {
  filter: blur(8px); /* Apply blur effect */
  opacity: 0.6; /* Slightly dim the item */
  pointer-events: none; /* Prevent interactions with blurred items */
}

.blurred-item:hover {
  filter: none; /* Remove blur on hover, if needed */
  opacity: 1;
  pointer-events: all;
}

.content-wrapper.blurred .blurred-item {
  filter: blur(8px); /* Blur only applies when the wrapper is blurred */
}

.login-button {
  width: 100%;
  padding: 5px;
  background: #008000;
  color: #1c1f4d;
  font-weight: bold;
  font-size: 1rem;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background 0.3s ease;
  margin-top: 5px; 
}
