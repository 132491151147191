.App {
  display: flex;
  flex-direction: column;
  color: white;
  font-family: Arial, sans-serif;
  text-align: center;
  transition: background 0.5s ease;
  animation: gradientAnimation 5s infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

h1 {
  font-size: 3rem;
}
